import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  thumbprintSubmitButton: {
    backgroundColor: '#fff',
    color: '#333',
    fontWeight: 'bold ',
    width: 200,
    alignItems: 'center',
    margin: 5,
  },
  container: {
    // width: '600px',
    // height: '400px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
  },

  circle: {
    borderRadius: '50%',
    backgroundColor: 'deepskyblue',
    width: '150px',
    height: '150px',
    position: 'absolute',
    opacity: 0,
    animationName: '$scaleIn',
    // animation: '$scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32)',
  },
  none: {
    borderRadius: '50%',
    backgroundColor: '#006bb5',
    width: '150px',
    height: '150px',
    position: 'absolute',
    opacity: 100,
  },
  success: {
    borderRadius: '50%',
    backgroundColor: '#4f8bc1',
    width: '300px',
    height: '300px',
    position: 'absolute',
    opacity: 0.6
  },
  item: {
    zIndex: 100,
    padding: '5px',
  },
  'item img': {
    width: '150px',
  },
  '@keyframes scaleIn': {
    from: { transform: 'scale(.5, .5)', opacity: 0.5 },
    to: { transform: 'scale(2.5, 3)', opacity: 0 },
  },
});
