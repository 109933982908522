import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  // event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export interface BreadcrumbItem {
  label: string;
  link: string;
  color: string;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
  // por
}

function ApplicantBreadcrumb(props: BreadcrumbProps): JSX.Element {
  return (
    <div role="presentation" style={{marginTop: '10px', color:"#fff"}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{color:"#fff"}}>
      {props.items.map((item) => {
        return(
        <Link underline="hover" color="#fff" href={item.link}>
          {item.label}
        </Link>)
      })}
      </Breadcrumbs>
    </div>
  );
}

export default ApplicantBreadcrumb;
