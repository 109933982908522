import axios from "axios";
import React, { useEffect } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";

interface NoProps {}

interface ChatBotServiceProps {
  steps: any;
}

interface ChatBotServiceState {
  loading: boolean;
  response: string;
}

interface MessageProps {
  message: string;
  steps: object[];
}

class ChatBotService extends React.Component<ChatBotServiceProps, ChatBotServiceState> {
  constructor(props: ChatBotServiceProps) {
    super(props);
    this.state = {
      loading: true,
      response: "",
    };
  }

  componentDidMount() {
    const { steps } = this.props;
    const query = steps.input.value;
    const xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", () => {
      if (xhr.readyState === 4) {
        const data = JSON.parse(xhr.responseText);
        this.setState({ loading: false, response: data.response });
      } else {
        this.setState({ loading: false, response: "" });
      }
    });

    xhr.open("POST", "https://v1com-emma.saicds.com/query");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify({ query, language: "en" }));
  }

  render() {
    const { loading, response } = this.state;

    return <div>{loading ? "Loading..." : response}</div>;
  }
}

const ChatBotComponent: React.FC<NoProps> = props => {
  const theme = {
    background: "#fff",
    headerBgColor: "#c4d34f",
    headerFontColor: "#3c6cb2",
    userBubbleColor: "#d0def2",
    fontFamily: "Helvetica Neue",
    headerFontSize: "16px"
  };

  let chatbotResponse = ".";

  const steps = [
    {
      id: "0",
      message: "Hi, I'm Emma! Type your question below.",
      trigger: "input"
    },
    {
      id: "input",
      user: true,
      trigger: "2"
    },
    {
      id: "2",
      component: <ChatBotService steps={{}} />,
      trigger: "input"
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <ChatBot steps={steps} headerTitle="Ask Emma" />
    </ThemeProvider>
  );
};

export default ChatBotComponent;
