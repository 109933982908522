import { createSlice } from "@reduxjs/toolkit";


export interface KioskData {
    name: string;
    schedule: {id: number, date: string, time: string, firstName: string, lastName: string, fingerprint: boolean, biometrics: boolean, idVerification: boolean, sex: string, height: string, country: string, dob: string, clientId: string}[];
    selectedId: number;
}

const initialState: KioskData = {
    name: "Blank",
    schedule: [
        {id: 1, date: '4/21/2022', time: '09:00AM', firstName: 'Roksana', lastName: 'Yakovlev', fingerprint: true, biometrics: true, idVerification: true, sex: 'FEMALE', height: '167.6 cm', country: 'Russia', dob: '12/26/1967', clientId: '86'},
        {id: 2, date: '4/21/2022', time: '11:30AM', firstName: 'Jun', lastName: 'Chu', fingerprint: true, biometrics: true, idVerification: true, sex: 'FEMALE', height: '157.5 cm', country: 'Taiwan', dob: '7/11/1989', clientId: '101'},
        {id: 3, date: '4/21/2022', time: '12:15PM', firstName: 'Bjoern', lastName: 'Michael', fingerprint: true, biometrics: true, idVerification: true, sex: 'MALE', height: '182.9 cm', country: 'Germany', dob: '4/24/1984', clientId: '29'},
        {id: 4, date: '4/21/2022', time: '01:30PM', firstName: 'Samir', lastName: 'Alibaba', fingerprint: true, biometrics: true, idVerification: true, sex: 'MALE', height: '176 cm', country: 'Saudi Arabia', dob: '5/26/1971', clientId: '87'},
        {id: 5, date: '4/21/2022', time: '04:45PM', firstName: 'Kevin', lastName: 'Miller', fingerprint: true, biometrics: true, idVerification: true, sex: 'MALE', height: '64 in', country: 'Austrailia', dob: '8/29/1996', clientId: '47'},
    ],
    selectedId: 0
};

const kioskSlice = createSlice({name: "config", initialState, 
reducers: {
    setKioskName: (state, action) => {
        state.name = action.payload;
    }, 
    setSelectedId: (state, action) => {
        state.selectedId = action.payload;
    },
    resetData: ()=> { return initialState;}}
})

export const {setKioskName, setSelectedId, resetData} = kioskSlice.actions;
export default kioskSlice.reducer;