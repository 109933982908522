import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { LocalAtm } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material/";
import Button from "@mui/material/Button";
// const matches = useMediaQuery('(min-width:600px)');
// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { PersonaContext } from "../../context/PersonaContext";
import Header from "../Header";

export interface Props {}

const CheckScanPage: React.FC<Props> = props => {
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  return (
    <>
      <Header></Header>
      <Grid container style={{ marginTop: 100 }}>
        <Grid item xs={3}></Grid>
        <Grid container item xs={6} justifyContent="center">
          <Stack spacing={6} justifyContent="center">
            <Typography
              variant="h3"
              align="center"
              sx={{
                fontWeight: "bold",
                color: "#fff",
                marginBottom: 0,
                fontFamily: "Poppins",
                letterSpacing: "3px"
              }}
            >
              {t("checkInsert1")}
            </Typography>
            <Box>
              <LocalAtm sx={{ fontSize: "96pt", color: "#fff" }} />
            </Box>
            <Button
              variant="contained"
              component={Link}
              to="/checkProcessing"
              style={{
                marginTop: 50,
                borderRadius: "50px",
                border: "3px solid rgb(1, 76, 128)",
                color: "rgb(1, 76, 128)",
                backgroundColor: "#fff",
                height: "70px",
                padding: "20px",
                width: "450px",
                fontFamily: "PoppinsNormal"
              }}
            >
              {t("checkInsert2")}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </>
  );
};

export default CheckScanPage;
