import React, {useEffect} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Box,
       Grid,
       Typography,
       Card,
       Paper,
       Avatar,
       Skeleton
} from '@mui/material/';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import LargeCard from '../../components/LargeCard/LargeCard';
import Header from '../Header'
import CropFreeIcon from '@mui/icons-material/CropFree';
import LinearProgress from '@mui/material/LinearProgress';


export interface Props {}

const RejectedStatusPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Header>
      <Grid container style={{marginTop: 100}}>
         <Grid item xs={3}></Grid>
         <Grid container item xs={6} justifyContent='center'>
            <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              REJECTED APPLICATION
            </Typography>
             <Stack spacing={6} style={{marginTop: 100, width:'100%', textAlign: 'center'}} justifyContent='center' justifyItems='center' >
                <Typography
                  variant='h4'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#BCD63E', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
                >
                  Thank you for submitting your N-400. Unfortunately your application was rejected due to incomplete information. A detailed explanation for rejection was emailed to your address on file. You will need to resubmit the N-400 form.
                </Typography>
                <Typography
                  variant='h4'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px', marginTop: '30px'}}
                >
                  Would you like us to email you a link to the N-400 form?
                </Typography>
                <Stack direction="row" spacing={3}>
                  <Button
                    variant='contained'
                    component={Link}
                    to='/formEmailed'
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                  >
                    Yes
                  </Button>
                  <Button
                    variant='contained'
                    component={Link}
                    to='/thankYou'
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                  >
                    Skip
                  </Button>
                </Stack>
              </Stack>
         </Grid>
         <Grid item xs={3}></Grid>
      </Grid>
    </Header>
  );
};

export default RejectedStatusPage;
