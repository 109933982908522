import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useStyles } from './styled';
import qrcode from '../../static/images/homelandsecurity-qr.png';
import Stack from '@mui/material/Stack';
import CardMedia from '@mui/material/CardMedia';
import Header from '../Header';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';

export interface Props {}

const QRPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  return (
    <Header>
    <Grid container style={{marginTop: 100}}>
       <Grid item xs={3}></Grid>
       <Grid container item xs={6} justifyContent='center'>
          <Typography
            variant='h3'
            align='center'
            sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
          >
            {t('thankyou')}
          </Typography>
           <Stack spacing={6} style={{marginTop: 60}} justifyContent='center' justifyItems='center'>
          <Box>
              <img src={qrcode} width='200' />
              <br/>
              <Button
                variant='contained'
                component={Link}
                to='/'
                style={{marginTop: 30, borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
              >
                {t('restart')}
              </Button>
              </Box>
            </Stack>
       </Grid>
       <Grid item xs={3}></Grid>
    </Grid>
  </Header>
  );
};

export default QRPage;
