import React, { useState } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import LargeCard from "../../components/LargeCard/LargeCard";
import { Link } from "react-router-dom";

import { useStyles } from "./styled";
import Header from "../Header/";
import { PersonaContext } from "../../context/PersonaContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../../context/LanguageContext";

export interface Props {}

const ThankYouPage: React.FC<Props> = props => {
  const classes = useStyles();
  const nextSteps = [
    "",
    "Biometrics Collection",
    "USCIS Interview",
    "Resubmit Application"
  ];

  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let [scenarioNum, setScenarioNum] = React.useState(0);

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    localStorage.setItem("setScheduleBack", "thankYou");
  }, []);

  const waitTimes = ["", t("wait1"), t("wait2"), t("wait3")];
  let scenarioNu

  useEffect(() => {
    if(localStorage.getItem('scenarioMode') === "naturalization"){
      setScenarioNum(1);
    } else if(localStorage.getItem('scenarioMode') === "greencard"){
      setScenarioNum(2);
    } else if(localStorage.getItem('scenarioMode') === "rejected"){
      setScenarioNum(3);
    }
  }, []);

  return (
    <PersonaContext.Consumer>
      {client => (
        <Header>
          <Grid container style={{ marginTop: 60 }}>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={8}
              justifyItems="center"
              justifyContent="center"
            >
              <Box>
                <Typography
                  variant="h3"
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    color: "#fff",
                    marginBottom: 5,
                    fontFamily: "Poppins",
                    letterSpacing: "3px",
                    justifyContent: "center",
                    justifyItems: "center"
                  }}
                >
                  {t("success")}
                </Typography>
              </Box>
               <Grid container xs={12} style={{ backgroundColor: '#fff', padding: '20px', height: '700px', borderRadius: '30px', borderColor: '#BCD63E', border: '7px solid #BCD63E' }}>
                <Grid item xs={3}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "left",
                      width: "350px",
                      padding: "10px",
                      color: "rgb(1, 76, 128)",
                      marginTop: "10px",
                      fontWeight: "bold",
                      fontFamily: "PoppinsNormal"
                    }}
                  >
                    {t("casenum")}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "10px",
                      color: "rgb(1, 76, 128)",
                      marginTop: "10px",
                      fontFamily: "PoppinsNormal"
                    }}
                  >
                    15432696
                  </Typography>
                </Grid>
                
                <Grid item xs={3}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "left",
                      width: "350px",
                      padding: "10px",
                      color: "rgb(1, 76, 128)",
                      marginTop: "10px",
                      fontWeight: "bold",
                      fontFamily: "PoppinsNormal"
                    }}
                  >
                    {t("nextsteps")}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "10px",
                      color: "rgb(1, 76, 128)",
                      marginTop: "10px",
                      fontFamily: "PoppinsNormal"
                    }}
                  >
                    {nextSteps[scenarioNum]} 
                    {localStorage.getItem('currentDate') != "" ? <>{t("currentScheduled")}
                    &nbsp;<Link to='/schedule'>{localStorage.getItem('currentDate')} at {localStorage.getItem('currentTime')}</Link></> : <></>}
                    
                    {localStorage.getItem('payLater') === 'true'? <>&nbsp;{t("outstanding")}</> : <></>}
                  </Typography>
                  {/* { localStorage.getItem('scenarioMode') != 'rejected' ? 
                      <Button
                        variant="contained"
                        component={Link}
                        to="/schedule"
                        style={{
                          borderRadius: "50px",
                          border: "3px solid rgb(1, 76, 128)",
                          color: "rgb(1, 76, 128)",
                          backgroundColor: "#fff",
                          height: "70px",
                          padding: "20px",
                          width: "450px",
                          fontFamily: "PoppinsNormal"
                        }}
                      >
                        Reschedule Appointment
                      </Button> :
                      <div/>
                  } */}
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "left",
                      width: "350px",
                      padding: "10px",
                      color: "rgb(1, 76, 128)",
                      marginTop: "10px",
                      fontWeight: "bold",
                      fontFamily: "PoppinsNormal"
                    }}
                  >
                    {t("waittimes")}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="h6"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      padding: "10px",
                      color: "rgb(1, 76, 128)",
                      marginTop: "10px",
                      fontFamily: "PoppinsNormal"
                    }}
                  >
                    {waitTimes[scenarioNum]}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      fontWeight: "bold",
                      color: "#BCD63E",
                      marginBottom: 0,
                      fontFamily: "PoppinsBold",
                      letterSpacing: "3px"
                    }}
                  >
                    {t("success2")}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "10px",
                      color: "rgb(1, 76, 128)",
                      marginTop: "10px",
                      fontFamily: "PoppinsNormal"
                    }}
                  >
                    {/* <a style={{textDecoration: 'none', color: '#BCD63E'}} href="../../all.html" target="_blank"> */}
                    {t("findClosest")}
                  </Typography>
                </Grid>
                <Grid xs={2}></Grid>
                <Grid xs={8}>
                  <Stack direction="row" spacing={6}>
                    <Button
                      variant="contained"
                      component={Link}
                      to="../../all.html"
                      target="_blank"
                      style={{
                        marginTop: 50,
                        borderRadius: "50px",
                        border: "3px solid rgb(1, 76, 128)",
                        color: "rgb(1, 76, 128)",
                        backgroundColor: "#BCD63E",
                        fontWeight: "bold",
                        height: "70px",
                        padding: "20px",
                        width: "450px",
                        fontFamily: "PoppinsNormal"
                      }}
                    >
                      {t("viewMap")}
                    </Button>
                    <Button
                      variant="contained"
                      component={Link}
                      to="/qr"
                      style={{
                        marginTop: 50,
                        borderRadius: "50px",
                        border: "3px solid rgb(1, 76, 128)",
                        color: "rgb(1, 76, 128)",
                        backgroundColor: "#fff",
                        height: "70px",
                        padding: "20px",
                        width: "450px",
                        fontFamily: "PoppinsNormal"
                      }}
                    >
                      {t("continue")}
                    </Button>
                  </Stack>
                </Grid>
                <Grid xs={2}></Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Header>
      )}
    </PersonaContext.Consumer>
  );
};

export default ThankYouPage;
