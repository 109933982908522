
import data from '../data/clientData.json';

interface IClient {
    client_id: number,
    first_name: string,
    middle_name: string,
    last_name: string,
    name_suffix: string,
    email: string,
    gender: string,
    birth_date: string,
    height: number,
    height_units: string,
    weight: number,
    weight_units: string,
    hair_color: string,
    eye_color: string,
    nationality: string,
    street_address: string,
    city: string,
    state: string,
    country: string,
    postal_code: number,
    phone_number: string,
    primary_language: string
  };

const getRandomInt = ():number =>  {
    return Math.floor(Math.random() * 9);
}

export const getClientData = () =>{
    let clientId : number = getRandomInt();

    const personaArray: any[] = data as any[];
    let client: any = personaArray[clientId];
    
    return client;
  }
