import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideocamIcon from '@mui/icons-material/Videocam';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import MLink from '@mui/material/Link';

// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import {
  Stack,
  Divider,
  Grid,
  Button

} from '@mui/material/';

import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AlertBox from '../../components/AlertBox';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import dashboard from "../../static/images/MiamiDashboard.png";
// MiamiDashboard

export interface Props { }

const AnalyticsPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const alert = () => {
    window.alert('Email is not configured yet.')
  }

  const goAdminScreen = () => {
    navigate(`/admin`);
  }

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  const handleCloseAlert = (event: React.MouseEvent<HTMLButtonElement>) => {

  };

  return (
    <Header>
      <Grid container xs={12} spacing={3} className="fadePanel" alignItems='center' sx={{height: '100vh'}} >
        <Grid item xs={12} alignItems='top'>
          <Card sx={{ maxWidth: '100%' }}>
            <CardMedia
              component="img"
              height="80%"
              image={dashboard}
              alt="Miami"
            />
          </Card>
        </Grid>
        <Grid item xs={12} alignItems='top'>
          <Button
            variant='contained'
            component={Link}
            to='/admin'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sx={{position: 'absolute', bottom: '30px'}}>
          <AlertBox open={open} onClick={(e) => {setOpen(false)}}/>
        </Grid>
      </Grid>
    </Header>
  );
};

export default AnalyticsPage;
