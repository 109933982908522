import React, { FC, useState, useContext } from 'react';

interface ILanguageContext {
  language: string;
  setLanguage: any;
}

export const LanguageContext = React.createContext<ILanguageContext>(
  {} as ILanguageContext
);

interface Props {}

const LanguageProvider: FC<Props> = (props: any) => {
  const context = useContext(LanguageContext);
  const [language, setLanguage] = useState(context);

  const setLanguageState = (language: string) => {
    setLanguage((prevState) => ({ ...prevState, language: language }));
  };

  return (
    <LanguageContext.Provider
      value={{
        ...language,
        setLanguage: setLanguageState,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider };
