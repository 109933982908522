import { makeStyles } from '@mui/styles';
import triangleBg from '../../static/images/borderchallengebk.png';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#c4d34f',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: '58px',
    padding: '0 30px',
    width:' 100%'
  },
  txtClr: {
    color: '#BCD63E'
  },
  background: {
    backgroundImage: 'url(' + triangleBg + ')',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'left bottom'
  },
  // btn: {
  //   backgroundColor: '#ffffff'
  // }
});

// import { makeStyles } from '../../themes';
// import { Theme } from '@mui/material/styles';

// export const useStyles = makeStyles()((button: Button) => ({
//   root: {
//     backgroundColor: button.palette.background.default,
//     color: button.palette.text.primary,
//   },
//   searchMenuStack: {
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
// }));
