import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideocamIcon from '@mui/icons-material/Videocam';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import MLink from '@mui/material/Link';
import Calendar from 'react-calendar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

// import 'react-calendar/dist/Calendar.css';

// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import {
  Stack,
  Divider,
  Grid,
  Button

} from '@mui/material/';

import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AlertBox from '../../components/AlertBox';
import { Http2ServerRequest } from 'http2';
import { scheduled } from "../../data/adminView";
import { useAppSelector, useAppDispatch } from '../../hooks/hooks'
import { setKioskName, setSelectedId, resetData } from '../../services/kioskSlice';
import styled from 'styled-components';
import { IScheduleChanges } from '../AdminReschedulePage';
import ApplicantBreadcrumb from '../../components/ApplicantBreadcrumb/ApplicantBreadcrumb';

export interface Props { }

interface ISchedule {
  scheduleId: number;
  date: string;
  time: string; 
  firstName: string; 
  lastName: string;
  fingerprint: boolean;
  biometrics: boolean;
  idVerification: boolean;
  sex: string;
  height: string;
  country: string;
  dob: string;
  clientId: string;
}

// interface
// {id: number, date: string, time: string, firstName: string, lastName: string, fingerprint: boolean, biometrics: boolean, idVerification: boolean, sex: string, height: string, country: string, dob: string, clientId: string}[];

const AdminSchedulePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [scheduleChanges, setScheduleChanges] = React.useState<IScheduleChanges[]>([]);

  // const schedule = useAppSelector((state: any) => state.kioskSettings.schedule);
  const dispatch = useAppDispatch()

  const alert = () => {
    window.alert('Email is not configured yet.')
  }

  // const [schedule, setSchedule] = React.useState<ISchedule[]>([]);
  const schedule = useAppSelector((state: any) => state.kioskSettings.schedule);

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    // const changes: IScheduleChanges[] = JSON.parse(localStorage.getItem('scheduleChanges') || String([]));
    // setScheduleChanges(changes);

    // console.log(`in schedule ${changes}`);
    // setSchedule();
  }, []);

  const selectedId = useAppSelector((state: any) => state.kioskSettings.selectedId)



  const handleViewDetails = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {

    dispatch(setSelectedId(index));
    // localStorage.setItem('selectedIndex', String(index));
    

    console.log(`abc: ${selectedId}`);


    let params: {
      scheduleId: number;
    };

    params = {
      scheduleId: index
    }

    navigate(`/adminScheduleDetails`, { state: {scheduleId: index}});
  };

 

  // Time Buttons
  // const [selected, setSelected] = React.useState<string | null>('09:00AM');

  // const [selected, setSelected] = React.useState<string | null>('09:00AM')

  const normalButtonStyle:any = {
    padding: '10px 20px', 
    width: '120px', 
    border: '1px solid white', 
    borderRadius: '10px', 
    color: '#ffffff',  
    fontWeight: 'bold', 
    fontSize: '22px'
  };

  const selectedButtonStyle:any = {
    padding: '10px 20px', 
    width: '120px', 
    border: '1px solid white', 
    borderRadius: '10px', 
    color: 'rgb(1, 76, 128)',  
    fontWeight: 'bold', 
    fontSize: '22px',
    backgroundColor: '#ffffff'
  };

  const Button2 = styled(Button)({
    // "&.Mui-selected, &.Mui-selected:hover": {
    //   color: 'rgb(1, 76, 128)',
    //   backgroundColor: 'white'
    // }
    '&:hover': {
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      boxShadow: 'none',
      color: 'rgb(1, 76, 128)'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      color: 'rgb(1, 76, 128)'
    },
    '&:selected': {
      boxShadow: 'none',
      backgroundColor: '#ffffff',
      borderColor: '#ffffff',
      color: 'rgb(1, 76, 128)'
    },
  });

  const breadcrumbs = [{label: "Schedule Main", link: "/adminSchedule", color: 'white'},
    ];
    
  return (
    <Header>
      <ApplicantBreadcrumb items={breadcrumbs}/>
      <Grid container xs={12} spacing={3} className="fadePanel" alignItems='center' sx={{height: '100vh'}} >
        <Grid item xs={6} alignItems='top'>

          <Stack spacing={4} justifyContent='center'>
            <Calendar/>  
          </Stack>
        </Grid>
        <Grid item xs={6}  >
          <Stack spacing={4} justifyContent='center'>
            <List sx={{width: '450px'}}>
              {JSON.parse(localStorage.getItem('scheduleChanges') || String([])).map((appt:any) => {
                return (
                  <ListItem key={appt.id}>
                    <Button2 sx={normalButtonStyle} value={appt.time} onClick={(e: any) => handleViewDetails(e, (appt.id-1))}>
                      {appt.time}
                    </Button2>
                  {/* <Button sx={{padding: '10px 20px', width: '120px', border: '1px solid white', borderRadius: '10px', color: '#ffffff',  fontWeight: 'bold', fontSize: '22px'}} onClick={(e) => handleViewDetails(e, (appt.id-1))}>
                    {appt.time}
                  </Button> */}
                  <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#ffffff', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                  {
                    <h2>
                      {appt.firstName} {appt.lastName}
                    </h2>}/>
                  <ListItemIcon>
                    <FingerprintIcon sx={{color: '#ffffff'}} />
                  </ListItemIcon>
              </ListItem>
                );
              })}
            </List>
          </Stack>
        </Grid>
        <Grid xs={12}>
        <Grid item xs={12} alignItems='top'>
          <Button
            variant='contained'
            component={Link}
            to='/admin'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
          >
            Back
          </Button>
        </Grid>
        </Grid>
      </Grid>
    </Header>
  );
};

export default AdminSchedulePage;
