import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideocamIcon from '@mui/icons-material/Videocam';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import MLink from '@mui/material/Link';
import Calendar from 'react-calendar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

// import 'react-calendar/dist/Calendar.css';

// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import {
  Stack,
  Divider,
  Grid,
  Button

} from '@mui/material/';

import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AlertBox from '../../components/AlertBox';
import { Http2ServerRequest } from 'http2';
import { scheduled } from "../../data/adminView";
import TimeBox from '../../components/TimeBox';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { IScheduleChanges } from '../AdminReschedulePage';
import styled from 'styled-components';

// Dialog 
interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;
  let navigate = useNavigate();

  const goAdminScreen = () => {
    onClose(selectedValue);
    navigate(`/thankYou`);
  }

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="alert-dialog-title">
          Appointment Scheduled
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          An email confirmation has been sent.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={goAdminScreen}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

export interface Props { }

interface IScheduule {
  scheduleId: number;
}

const Button2 = styled(Button)({
  // "&.Mui-selected, &.Mui-selected:hover": {
  //   color: 'rgb(1, 76, 128)',
  //   backgroundColor: 'white'
  // }
  '&:hover': {
    backgroundColor: '#ffffff',
    borderColor: '#ffffff',
    boxShadow: 'none',
    color: 'rgb(1, 76, 128)'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderColor: '#ffffff',
    color: 'rgb(1, 76, 128)'
  },
  '&:selected': {
    boxShadow: 'none',
    backgroundColor: '#ffffff',
    borderColor: '#ffffff',
    color: 'rgb(1, 76, 128)'
  },
});

const SchedulePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [scheduleChanges, setScheduleChanges] = React.useState<IScheduleChanges[]>([],);
  
  console.log(context.first_name);
  console.log(`scenario----${context.scenario}`);

  const times = ["09:00AM", "09:15AM", "09:30AM", "10:30AM", "12:00PM", "01:15PM", "02:00PM", "04:30PM"];
  const alert = () => {
    window.alert('Email is not configured yet.')
  }

  

  // console.log(currentSchedule);
  // console.log("--------");
  const [selected, setSelected] = React.useState<string | null>("09:00AM");

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  useEffect(() => {
    let currentTime = (localStorage.getItem('currentTime') || "");
    if (currentTime != undefined && currentTime != "") {
      setSelected(currentTime);
    }
  }, []);

  const handleViewDetails = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(`target: ${event.currentTarget.value}`);
    setSelected(event.currentTarget.value);
    localStorage.setItem('currentTime', event.currentTarget.value);

    const date: Date = new Date();
    localStorage.setItem('currentDate', date.toDateString());

    // console.log("hello");

    // event.
    // button.
    // console.log(`index: ${index}`);

    // let params: {
    //   scheduleId: number;
    // };

    // params = {
    //   scheduleId: index
    // }

    // navigate(`/adminScheduleDetails`, { state: {scheduleId: index}});
  };

  const [selectedValue, setSelectedValue] = React.useState("09:00AM");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const normalButtonStyle:any = {
    padding: '10px 20px', 
    width: '120px', 
    border: '1px solid white', 
    borderRadius: '10px', 
    color: '#ffffff',  
    fontWeight: 'bold', 
    fontSize: '22px'
  };

  const selectedButtonStyle:any = {
    padding: '10px 20px', 
    width: '120px', 
    border: '1px solid white', 
    borderRadius: '10px', 
    color: 'rgb(1, 76, 128)',  
    fontWeight: 'bold', 
    fontSize: '22px',
    backgroundColor: '#ffffff'
  };
  
  return (
    <Header>
      <Grid container xs={12} spacing={3} className="fadePanel" alignItems='center' sx={{height: '100vh'}} >
        <Grid item xs={6} alignItems='top'>

          <Stack spacing={4} justifyContent='center'>
            <Calendar/>  
          </Stack>
        </Grid>
        <Grid item xs={6}  >
          {/* <Stack spacing={4} justifyContent='center'> */}
            {/* <List sx={{width: '450px'}}> */}
            <Typography
                variant='h4'
                align='center' 
                sx={{ paddingBottom: '30px', fontFamily: 'PoppinsNormal', fontWeight: 'bold', color: '#fff', marginBottom: 0, letterSpacing: '3px'}}
              >
                Open Timeslots Available
              </Typography>
            <Grid container spacing={4}>
              {times.map((time) => {
                return (
                  <Grid item>
                    <Button2 sx={time === selected ? selectedButtonStyle : normalButtonStyle} value={time} onClick={(e) => handleViewDetails(e)}>
                      {time}
                    </Button2>
                  </Grid>
                );
              })}

            <Typography
                align='center' 
                sx={{ paddingTop: '30px', fontFamily: 'PoppinsNormal', fontSize: '18px', fontWeight: 'bold', color: '#BCD63E', marginBottom: 0, letterSpacing: '3px'}}
              >
                *Showing earliest available next appointments
              </Typography>
            </Grid>
            {/* </List> */}
          {/* </Stack> */}
        </Grid>
        <Grid item xs={2}  ></Grid>
        <Grid item xs={8}>
          <Stack direction="row" spacing={3}>
            <Button
              variant='contained'
              component={Link}
              to={`/${localStorage.getItem('setScheduleBack')}`}
              style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={handleClickOpen}
              style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            >
              Schedule
            </Button>
            </Stack>
        </Grid>
        <Grid item xs={2}  ></Grid>
        <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
      </Grid>
    </Header>
  );
};

export default SchedulePage;
