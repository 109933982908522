import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import {Grid} from '@mui/material/';
import saicTri from '../../static/images/saicTri.png';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import triangleBg from '../../static/images/borderchallengebk.png';
import { PersonaContext } from '../../context/PersonaContext';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface Props { }

interface IClient {
  client_id: number,
  first_name: string,
  middle_name: string,
  last_name: string,
  name_suffix: string,
  email: string,
  gender: string,
  birth_date: string,
  height: number,
  height_units: string,
  weight: number,
  weight_units: string,
  hair_color: string,
  eye_color: string,
  nationality: string,
  street_address: string,
  city: string,
  state: string,
  country: string,
  postal_code: number,
  phone_number: string,
  primary_language: string
};

const SelectLanguagePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const context = useContext(LanguageContext);
  const personaContext = useContext(PersonaContext);
  // const [client, setClient] = useState(context);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const changeLanguageHandler = (languageValue: string) => {
    // const languageValue = e.target.value;
    i18n.changeLanguage(languageValue);
    context.setLanguage(languageValue);

    // let clientId : number = getRandomInt();
    // let client: any = data[clientId];
    // localStorage.setItem('clientId', JSON.stringify(client));

    personaContext.createClient();
    navigate(`/persona`);


    navigate(`/persona`, { state: { language: languageValue} });
  };

  const handleClick = (msg: string) => {
    setMessage(`${msg} coming soon!`);
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
    <img src={triangleBg} style={{position: 'absolute', top: -300, left: -125}}/>
    <Grid container style={{marginTop: 100}}>
      <Grid item xs={4}></Grid>
      <Grid container item xs={4} justifyContent='center'>
         <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              SELECT LANGUAGE
            </Typography>
            {/* <LargeCard> */}
              <Stack spacing={4} style={{marginTop: 100}} justifyContent='center'>
              <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              changeLanguageHandler('en');
            }}
          >
            English
          </Button>
          <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              changeLanguageHandler('es');
            }}
          >
            Español
          </Button>
          <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              // alert("French coming soon!");
              handleClick("French");
            }}
          >
            Français
          </Button>
          <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              handleClick("Mandarin");
            }}
          >
            普通话
          </Button>
          <Button
            variant='contained'
            style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
            className={classes.languageButton}
            onClick={() => {
              handleClick("Other languages");
            }}
          >
            Other languages
          </Button>
          </Stack>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '350px' }}>
              {message}
            </Alert>
          </Snackbar>
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
    </>
  );
};

export default SelectLanguagePage;
