import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  box: {
    backgroundColor: 'white',
    border: 0,
    borderRadius: 15,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'black',
    padding: '20px',
  },
  languageButton: {
    backgroundColor: '#E1E1E1',
    color: 'black',
    fontWeight: 'bold ',
    padding: 30,
  },
  personaBox: {
    // backgroundColor: 'white',
    // padding: '50px',
    // borderRadius: 10,
    width: '60%',
    height: '100vh',
    maxHeight: '400px',
  },
  submitButton: {
    backgroundColor: '#3c6cb2',
    color: 'white',
    fontWeight: 'bold ',
    width: '50%'
  },
  'ocrloader p::before': {
    content: '',
    display: 'inline-block',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    background: '#18c89b',
    position: 'relative',
    right: '4px',
  },
  'ocrloader p': {
    color: '#18c89b',
    position: 'absolute',
    bottom: '-30px',
    left: '38%',
    fontSize: '16px',
    fontWeight: '600',
    animation: 'blinker 1.5s linear infinite',
    fontFamily: 'sans-serif',
    textTransform: 'uppercase',
  },
  ocrloader: {
    width: '360px',
    height: '225px',
    position: 'absolute',
    left: '50%',
    webkitTransform: 'translateX(-50%)',
    transform: 'translateX(-50%)',
    top: '40%',
    backfaceVisibility: 'hidden',
    border: 1,
  },
  'ocrloader span': {
    position: 'absolute',
    left: '35px',
    top: '0',
    width: '85%',
    height: '5px',
    backgroundColor: '#18c89b',
    boxShadow: '0 0 10px 1px #18c89b',
    //  "0 0 1px 1px #18c89b",
    zIndex: '1',
    transform: 'translateY(95px)',
    animation: 'move 1.7s cubic-bezier(0.15,0.54,0.76,0.74)',
    animationIterationCount: 'infinite',
  },
  'ocrloader:before, ocrloader:after, ocrloader em:after, ocrloader em:before':
    {
      borderColor: '#18c89b',
      content: '',
      position: 'absolute',
      width: '45px',
      height: '46px',
      borderStyle: 'solid',
      borderWidth: '0px',
    },
  'ocrloader:before': {
    left: '0',
    top: '0',
    borderLeftWidth: '5px',
    borderTopWidth: '5px',
    borderRadius: '5px 0 0 0',
  },
  'ocrloader:after': {
    right: '0',
    top: '0',
    borderRightWidth: '5px',
    borderTopWidth: '5px',
    borderRadius: '0 5px 0 0',
  },
  'ocrloader em:before': {
    left: '0',
    bottom: '0',
    borderLeftWidth: '5px',
    borderBottomWidth: '5px',
    borderRadius: '0 0 0 5px',
  },
  'ocrloader em:after': {
    right: '0',
    bottom: '0',
    borderRightWidth: '5px',
    borderBottomWidth: '5px',
    borderRadius: '0 0 5px 0',
  },
  languageBox: {
    backgroundColor: 'white',
    padding: '50px',
    borderRadius: 10,
    width: '60%',
    height: 'auto',
  },
  svg_icons: {
    transform: "scale(5)"
  }
  // '@keyframes move': {
  //   '0% 100%': {
  //     transform: 'translateY(190px)',
  //   },
  //   '50%': {
  //     transform: 'translateY(0%)',
  //   },
  //   '75%': {
  //     transform: 'translateY(160px)',
  //   },
  // },
  // '@keyframes blinker': {
  //   '50%': { opacity: '0' },
  // },
});
