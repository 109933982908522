import {
    SxProps,
    Theme,
    Typography,
  } from '@mui/material';
  import React from 'react';
  
  import moment from 'moment';
  
  export interface DateTimeProps {
    timestamp:string;
    format:string;
    sx?: SxProps<Theme>;
  }
  
  const DateTime:React.FC<DateTimeProps> = (props) => {
    const { timestamp, format, sx=[] } = props;
    const dateTime = moment(timestamp).format(format);
  
    return (
      <Typography sx={[{width: '100%'}, ...(Array.isArray(sx) ? sx : [sx]),]}>
        {dateTime}
      </Typography>
    );
  };
  
  export default DateTime;