import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open : boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};


export default function AlertBox(props: Props) {
  // const [open, setOpen] = React.useState(props.open);
  // const [open, setOpen] = React.useState(true);

  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.onClick}
        aria-describedby="alert-dialog-slide-description"
       
      >
        <DialogTitle>{t('alertboxTitle')}</DialogTitle>
        <DialogContent  style={{width: '500px'}} >
          <DialogContentText id="alert-dialog-slide-description">
          {t('alertboxDesc')}<br/><br/>
          <Typography style={{padding: '10px', fontSize: '16px',color: '#fff', marginTop: '10px', fontFamily: 'PoppinsNormal'}} ><a href="http://localhost/tx.html" target="_blank">{t('benefits4')}</a></Typography>
                  
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClick}>{t('continue')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
