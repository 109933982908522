import React, {useEffect} from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Box,
       Grid,
       Typography,
       Card,
       Paper,
       Avatar,
       Skeleton
} from '@mui/material/';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import LargeCard from '../../components/LargeCard/LargeCard';
import Header from '../Header'
import CropFreeIcon from '@mui/icons-material/CropFree';
import LinearProgress from '@mui/material/LinearProgress';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ApplicantBreadcrumb from '../../components/ApplicantBreadcrumb/ApplicantBreadcrumb';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface Props {}

const BiometricsNowPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  // const [message, setMessage] = React.useState("");

  const handleClick = (msg: string) => {
    // setMessage(`${msg} coming soon!`);
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const breadcrumbs = [{label: "Kiosk Authentication", link: "/kioskAuth", color: 'white'},
    {label: "Passport OCR Scan", link: "/passportOcrScan", color: 'white'},
    {label: "Scan", link: "/scanProgress", color: 'white'},
    {label: "Passport ID Verified", link: "/passportIdCompare", color: 'white'},
    {label: "ID Confirmed", link: "/passportOcr", color: 'white'},
    {label: "Timeline", link: "/timeline", color: 'white'},
    {label: "Biometrics", link: "/biometricsNow", color: 'white'},
  ];

  return (
    <Header>
      <ApplicantBreadcrumb items={breadcrumbs}/>
      <Grid container style={{marginTop: 100}}>
         <Grid item xs={3}></Grid>
         <Grid container item xs={6} justifyContent='center'>
            <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              Biometrics Required
            </Typography>
             <Stack spacing={6} style={{marginTop: 100, width:'100%', textAlign: 'center'}} justifyContent='center' justifyItems='center' >
                <Typography
                  variant='h4'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#BCD63E', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
                >
                  It’s time to collect your biometrics in order to continue processing your green card application.
                </Typography>
                <Typography
                  variant='h4'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px', marginTop: '30px'}}
                >
                  Please collect your Biometrics now using the Biokiosk, or schedule an appointment in the future for a later date at an ASC Site.
                </Typography>
                <Stack direction="row" spacing={3}>
                  <Button
                    variant='contained'
                    component={Link}
                    to='/biometrics'
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                  >
                    Collect Biometrics
                  </Button>
                  <Button
                    variant='contained'
                    component={Link}
                    to='/schedule'
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                  >
                    Schedule Appointment
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setOpen(true);
                    }}
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                  >
                    Request ADA Appointment
                  </Button>
                </Stack>
              </Stack>
              <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '350px' }}>
                  Coming Soon
                </Alert>
              </Snackbar>
         </Grid>
         <Grid item xs={3}></Grid>
      </Grid>
    </Header>
  );
};

export default BiometricsNowPage;
