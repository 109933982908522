import { configureStore, ThunkAction, Action} from '@reduxjs/toolkit'
import { getJSDocReturnType } from 'typescript';
import kioskSliceReducer from '../services/kioskSlice';
// ...

export const store = configureStore({
  reducer: {
    kioskSettings: kioskSliceReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<String>
>
  export default store;
