import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Box, Card, Grid, Skeleton, Typography } from "@mui/material/";
import LinearProgress from "@mui/material/LinearProgress";
// const matches = useMediaQuery('(min-width:600px)');
// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { PersonaContext } from "../../context/PersonaContext";
import Header from "../Header";
import { useStyles } from "./styled";

export interface Props {}

const CheckProcessingPage: React.FC<Props> = props => {
  const classes = useStyles();

  let navigate = useNavigate();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    setTimeout(() => navigate("/paymentProcessing"), 3000);
  }, []);

  return (
    <>
      <Header />
      <Grid container style={{ marginTop: 100 }}>
        <Grid item xs={3}></Grid>
        <Grid container item xs={6} justifyContent="center">
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: "bold",
              color: "#fff",
              marginBottom: 0,
              fontFamily: "Poppins",
              letterSpacing: "3px"
            }}
          >
            {t("scanprogress")}
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
      <Grid container style={{ marginTop: 80 }}>
        <Grid item xs={3}></Grid>
        <Grid container item xs={6} justifyContent="center">
          <div className="scanner">
            <Card
              style={{ padding: "50px", width: "100%" }}
              elevation={0}
              className="fingarprint"
            >
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography className="pulse"></Typography>
                  <div className="spacer" />
                </Grid>
                <Grid item xs={12} style={{ margin: "0 auto" }}>
                  <Box
                    style={{
                      background: "#929292",
                      height: "100%",
                      width: "100%",
                      borderRadius: "20px",
                      padding: "10px"
                    }}
                  >
                    <Skeleton
                      variant="text"
                      animation="wave"
                      style={{ marginTop: "20px" }}
                      width={200}
                    />
                    <Skeleton
                      variant="text"
                      style={{ marginTop: "50px" }}
                      width={500}
                      animation="wave"
                    />
                    <Skeleton
                      variant="text"
                      style={{ width: "100%", marginTop: "50px" }}
                      animation="wave"
                    />
                  </Box>
                </Grid>
                <div className="spacer" />

                <Grid item xs={12}></Grid>
              </Grid>
            </Card>
            <Stack
              spacing={6}
              style={{ marginTop: 100, width: "100%", textAlign: "center" }}
              justifyContent="center"
              justifyItems="center"
            >
              <LinearProgress />
            </Stack>
          </div>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </>
  );
};

export default CheckProcessingPage;
