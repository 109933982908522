import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  passportSubmitButton: {
    backgroundColor: '#006BB5',
    color: 'white',
    fontWeight: 'bold ',
    width: 200,
    alignItems: 'center',
    margin: 15,
  },
});
