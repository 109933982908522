import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export interface Props {
  width?: number;
  height?: string;
  title?: string;
  children?: React.ReactNode;
}

// const PrintName2 = ({ prop1, prop2 }: Props): JSX.Element => { /** */}
// function MyComponentCorrect({ children }: Props): JSX.Element {}
function LargeCard({ width, height, title, children }: Props): JSX.Element {
  return (
    <Card
      sx={{
        width: { width },
        height: { height },
        minWidth: 350,
        margin: 5,
        alignItems: 'center',
        background: 'transparent',
       backgroundImage: 'linear-gradient(to bottom, #ffffff, #def4ff)'
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
       
         
          }}
        >
          <Typography
            sx={{ fontSize: 26, fontWeight: 'bold' }}
            style={{ textAlign: 'center'}}

            gutterBottom
          >
            
            {title}
          </Typography>
         
          {children}
        </Box>
      </CardContent>
    </Card>
  );
}

export default LargeCard;
