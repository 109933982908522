import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideocamIcon from '@mui/icons-material/Videocam';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import MLink from '@mui/material/Link';

// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import {
  Stack,
  Divider,
  Grid,
  Button

} from '@mui/material/';

import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AlertBox from '../../components/AlertBox';
import Tour from "reactour";

export interface Props { }

const AdminPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const alert = () => {
    window.alert('Email is not configured yet.')
  }

  const goAdminScreen = () => {
    navigate(`/admin`);
  }

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  const handleCloseAlert = (event: React.MouseEvent<HTMLButtonElement>) => {

  };

  let adminInitialeVar: boolean =
    localStorage.getItem("adminInitial") == "true" ? true : false;
  const [tourOpen, setTourOpen] = React.useState(adminInitialeVar);

  const steps = [
    {
      // selector: '.first-step',
      content: () => (
        <Box sx={{height: '400px', width: '600px'}}/>
      )

    },
    {
      // selector: '.first-step',
      content:
        "You need to adjust Roksana Yakovlev to 9:30AM to accommodate a change in schedule."
        // "You're an USCIS employee manning a Biokiosk at a USCIS site."
    },
    {
      content: "Please select the schedule button to make the adjustment."
    }
  ];

  const closeTour = () => {
    setTourOpen(false);
    localStorage.setItem("adminInitial", "false");
  };

  return (
    <Header>
      <Grid container xs={12} spacing={3} className="fadePanel" alignItems='center' sx={{height: '100vh'}} >
      {/* <Tour steps={steps} isOpen={tourOpen} onRequestClose={closeTour} /> */}
        <Grid item xs={6} alignItems='top'>

        <Stack spacing={4} justifyContent='center'>
            <Link to={`/adminSchedule`} style={{ textDecoration: 'none' }}>
              <LargeCard height='200px'>
                <Typography
                  variant='h1'
                  component='h2'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#3c6cb2', textAlign: 'center', fontSize: '40px', marginTop: '50px', fontFamily: 'PoppinsNormal' }}
                > <CalendarMonthIcon sx={{fontSize: '24pt'}}/>&nbsp;
                  {t('schedule')}
                  <span className="pageGradient"></span>
                </Typography>
              </LargeCard>
            </Link>

              <Link to={`/camera`} style={{ textDecoration: 'none' }}>
                <LargeCard height='200px'>
                  <Typography
                    variant='h1'
                    component='h2'
                    align='center'
                    sx={{ fontWeight: 'bold', color: '#3c6cb2', textAlign: 'center', fontSize: '40px', marginTop: '50px', fontFamily: 'PoppinsNormal'}}
                  ><VideocamIcon sx={{fontSize: '24pt'}}/>&nbsp;
                    {t('camera')}
                  </Typography>
                </LargeCard>
              </Link>
            </Stack>
        </Grid>
        <Grid item xs={6}  >
          <Stack spacing={4} justifyContent='center'>
            <Link to={`/analytics`} style={{ textDecoration: 'none' }}>
              <LargeCard height='200px'>
                <Typography
                  variant='h1'
                  component='h2'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#3c6cb2', textAlign: 'center', fontSize: '40px', marginTop: '50px', fontFamily: 'PoppinsNormal' }}
                > <InsertChartIcon sx={{fontSize: '24pt'}}/>&nbsp;
                  {t('analytics')}
                  <span className="pageGradient"></span>
                </Typography>
              </LargeCard>
            </Link>

              <MLink 
               style={{ textDecoration: 'none' }}
                  component="button"
                  variant="body2"
                  onClick={() => {
                    console.info("Not yet implemented");
                    setOpen(true);
                  }}>
                <LargeCard height='200px'>
                  <Typography
                    variant='h1'
                    component='h2'
                    align='center'
                    sx={{ fontWeight: 'bold', color: '#3c6cb2', textAlign: 'center', fontSize: '40px', marginTop: '50px', fontFamily: 'PoppinsNormal'}}
                  ><SettingsIcon sx={{fontSize: '24pt'}}/>&nbsp;
                    Settings
                  </Typography>
                </LargeCard>
              </MLink>
            </Stack>

        </Grid>
        <Grid item xs={12} sx={{position: 'absolute', bottom: '30px'}}>
          <AlertBox open={open} onClick={(e) => {setOpen(false)}}/>
        </Grid>
      </Grid>
    </Header>
  );
};

export default AdminPage;
