import axios from 'axios';

export interface User {
  firstName: string;
  lastName: string;
  email: string;

}

// const instance = axios.create({
//   baseURL: 'http://jsonplaceholder.typicode.com/',
//   timeout: 15000,
// });

// const instance = axios.create({
//   baseURL: 'http://localhost:3002',
//   timeout: 15000,
// });

const base = axios.create({
  // baseURL: 'http://54.144.117.49:8082/',
  // baseURL:'http://localhost:8082/', 
  baseURL: 'https://border-security-expo-demo-ec2.saicds.com/api/expo/',
  timeout: 15000,
});

export const getPosts = () => {
  return [];
};

// // make a module in there
// export const createVisitorRecord = (user: User) => {
//   return instance.post<User>('/getvisitor', user);
// };

// export const getVisitorRecord = (id: any) => {
//   return instance.get<User>(`/visitor/${id}`);
// };

export const emailExist = (email: User) =>{
  

  return base.post('/emailexist', email);
}

export const checkEmail = (email: any) =>{
  // email

  return base.post('/emailexist', email);
}

export const getClientData = (id :any) =>{
  //  client_id 

  return base.post('/getclientbyid', id);
}


export const createNewVisitorRecord = (user: User) => {
  return base.post<User>('/createvisitor', user);
};


export const createNewVisitor = (formValues: any) =>{
  // first_name , last_name , email
console.log("in create new visitor");

  return base.post('/createvisitor', formValues);
}

export const updateVisitor = (formValues: any) =>{
  // visitor_id

  return base.post('/updatevisitor', formValues);
}

export const clientScenarioProcess = (formValues: any) =>{
  //  client_id , scenario_id

  return base.post('/getprocessesbyclientscenario', formValues);
}
