import React, { useEffect } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  Box,
  Grid,
  Typography,
  Card,
  Paper,
  Avatar,
  Skeleton
} from "@mui/material/";

import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SplitPane2 from "../../components/SplitPane2/SplitPane2";
// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useStyles } from "./styled";
import LargeCard from "../../components/LargeCard/LargeCard";
import Header from "../Header";
import CropFreeIcon from "@mui/icons-material/CropFree";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { PersonaContext } from "../../context/PersonaContext";
import { LanguageContext } from "../../context/LanguageContext";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import kioskadmin from "../../static/images/kioskadmin.jpg";
import TextField from "@mui/material/TextField";
import ApplicantBreadcrumb from '../../components/ApplicantBreadcrumb/ApplicantBreadcrumb';

// Dialog
interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

interface AdminDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  onEnter: () => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;  
  let navigate = useNavigate();

  const goAdminScreen = () => {
    onClose(selectedValue);
    
    navigate(`/admin`);
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="alert-dialog-title">Admin Pin</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please enter your 6 digit pin to enter admin screen
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Pin Number"
          type="pin"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={goAdminScreen}>Enter</Button>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AdminOnboardDialog(props: AdminDialogProps) {
  const { onClose, onEnter, selectedValue, open } = props;
  let navigate = useNavigate();
  let adminModeVar: boolean =
    localStorage.getItem("scenarioMode") == "admin" ? true : false;


  const goAdminScreen = () => {
    onClose(selectedValue);
    onEnter();
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{fontSize: '2.25rem'}} id="alert-dialog-title">Admin Scenario</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
        <img src={kioskadmin} style={{height: '150px'}}/>
        <Typography
            component="div"
            align="left"
            sx={{
              fontWeight: 800,
              color: "#3c6cb2",
              marginBottom: 2,
              width: "100%",
              // paddingLeft: "70px",
              fontSize: "18px",
              alignItems: "top",
              fontFamily: "PoppinsNormal"
            }}
          >
            In this scenario, you will be playing the role of Michael Gonzalez who is a VICOM Biokiosk administrator.<br/><br/>

            You will be prompted to provide your pin in order to enter the administrative console. <br/><br/>
            You need to adjust Roksana Yakovlev to 9:30AM to accommodate a change in schedule. <br/><br/>
            Once you are in the administrative screen, please select the schedule button to make the adjustment.
          </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={goAdminScreen}>Next</Button>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export interface Props {}

const PassportOCRScanProgressPage: React.FC<Props> = props => {
  const classes = useStyles();

  let navigate = useNavigate();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [state, setState] = React.useState({
    isLoading: false
  });

  useEffect(() => {
    i18n.changeLanguage(langContext.language);

    if (localStorage.getItem("scenarioMode") != "admin") {
      setTimeout(() => {
        navigate(`/passportIdCompare`);
      }, 5000);
    }
  }, []);

  // for admin dialog
  const [open, setOpen] = React.useState(false);
  const [openAdminOnboard, setOpenAdminOnboard] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("abc");

  const handleClickOpen = () => {
    setOpenAdminOnboard(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleAdminOnboardClose = (value: string) => {
    setOpenAdminOnboard(false);
    setSelectedValue(value);
  };

  const currentClient: any = JSON.parse(localStorage.getItem("currentClient") || '{}');
  const breadcrumbs = [{label: "Kiosk Authentication", link: "/kioskAuth", color: 'white'},
    {label: "Passport OCR Scan", link: "/passportOcrScan", color: 'white'},
    {label: "Scan", link: "/scanProgress", color: 'white'},
  ];

  return (
    <Header>
      <ApplicantBreadcrumb items={breadcrumbs}/>
      <Grid container style={{ marginTop: 100 }}>
        <Grid item xs={3}></Grid>
        <Grid container item xs={6} justifyContent="center">
          <Typography
            variant="h3"
            align="center"
            sx={{
              fontWeight: "bold",
              color: "#fff",
              marginBottom: 0,
              fontFamily: "Poppins",
              letterSpacing: "3px"
            }}
          >
            {t("scanprogress")}
          </Typography>
          <Stack
            spacing={6}
            style={{ marginTop: 100, width: "100%", textAlign: "center" }}
            justifyContent="center"
            justifyItems="center"
          >
            {/* <LinearProgress /> */}
            <div className="scanner">
              <Card
                style={{ padding: "50px", width: "80%" }}
                elevation={0}
                className="fingarprint"
              >
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    <Typography className="pulse"></Typography>
                    <div className="spacer" />
                  </Grid>
                  <Grid item xs={12} style={{ margin: "0 auto" }}>
                    <Box
                      style={{
                        background: "#929292",
                        height: "100%",
                        width: "100%",
                        borderRadius: "20px",
                        padding: "10px"
                      }}
                    >
                      <Stack spacing={1} style={{ margin: "0 auto" }}>
                        <Skeleton
                          variant="circular"
                          animation="wave"
                          style={{
                            margin: "0 auto",
                            width: "125px",
                            height: "125px"
                          }}
                        />
                        <Skeleton
                          variant="rectangular"
                          style={{ width: "100%" }}
                          height={150}
                          animation="wave"
                        />
                      </Stack>
                    </Box>
                  </Grid>
                  <div className="spacer" />

                  <Grid item xs={12}>
                  </Grid>
                </Grid>
              </Card>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={3}>
        { localStorage.getItem("scenarioMode") == "admin" ? 
                      <Button
                        variant="outlined"
                        sx={{
                          color: "#ffffff",
                          borderColor: "#ffffff",
                          position: "absolute",
                          bottom: "30px",
                          right: "30px"
                        }}
                        startIcon={<AdminPanelSettingsIcon />}
                        onClick={handleClickOpen}
                      >
                        Admin
                      </Button> :
                      <div/>
                  }
                  <SimpleDialog
                    selectedValue={selectedValue}
                    open={open}
                    onClose={handleClose}
                  />
                  <AdminOnboardDialog
                    selectedValue={selectedValue}
                    open={openAdminOnboard}
                    onClose={handleAdminOnboardClose}
                    onEnter={() => {setOpen(true)}}
                  />
        </Grid>
      </Grid>
    </Header>
  );
};

export default PassportOCRScanProgressPage;
