import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  box: {
    backgroundColor: 'white',
    border: 0,
    borderRadius: 15,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'black',
    padding: '20px',
  },
  languageButton: {
    backgroundColor: '#E1E1E1',
    color: 'black',
    fontWeight: 'bold ',
    padding: 30,
  },
  personaBox: {
    // backgroundColor: 'white',
    // padding: '50px',
    // borderRadius: 10,
    width: '60%',
  },
});
