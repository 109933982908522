import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  box: {
    backgroundColor: 'white',
    border: 0,
    borderRadius: 15,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'black',
    padding: '20px',
  },
  listButton: {
    border: '1px solid white', 
    borderRadius: '10px', 
    color: '#ffffff', 
    fontWeight: 'bold', 
    fontSize: '22px'
  }
});
