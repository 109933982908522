import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useStyles } from './styled';
import Stack from '@mui/material/Stack';

export interface Props {}

const SignaturePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      minHeight='100vh'
      maxWidth='150px'
    >
      <LargeCard>
        Signature Goes here
        <Button
          variant='contained'
          className={classes.passportSubmitButton}
          component={Link}
          to='/visitorReg'
        >
          Ok
        </Button>
      </LargeCard>
    </Box>
  );
};

export default SignaturePage;
