import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#ffffff',
    margin: 0

  },

  
})

