import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  timeline: {
    transform: 'rotate(90deg)',
  },
  timelineContentContainer: {
    textAlign: 'left',
  },
  timelineContent: {
    display: 'inline-block',
    transform: 'rotate(-90deg)',
    textAlign: 'center',
    minWidth: 50,
  },
  timelineIcon: {
    transform: 'rotate(-90deg)',
  },
  submitButton: {
    backgroundColor: 'white',
    color: '#006BB5',
    fontWeight: 'bold ',
    width: 200,
    alignItems: 'center',
    margin: 15,
  },
});
