import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useMediaQuery } from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useNavigate } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideocamIcon from '@mui/icons-material/Videocam';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SettingsIcon from '@mui/icons-material/Settings';
import MLink from '@mui/material/Link';
import Calendar from 'react-calendar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FingerprintIcon from '@mui/icons-material/Fingerprint';

// import 'react-calendar/dist/Calendar.css';

// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import {
  Stack,
  Divider,
  Grid,
  Button

} from '@mui/material/';

import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Header from '../Header';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AlertBox from '../../components/AlertBox';
import { Http2ServerRequest } from 'http2';
import { scheduled } from "../../data/adminView";
import { useAppSelector, useAppDispatch } from '../../hooks/hooks'
import { setKioskName, setSelectedId, resetData } from '../../services/kioskSlice';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ApplicantBreadcrumb from '../../components/ApplicantBreadcrumb/ApplicantBreadcrumb';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
}

function CancelDialog(props: ConfirmationDialogProps) {
  const { onClose, open } = props;
  let navigate = useNavigate();

  const [header, setHeader] = React.useState("Confirm Cancellation?");
  const [message, setMessage] = React.useState("Are you sure you want to cancel this appointment?");
  const cancelAppointment = () => {
    setHeader("Appointment Cancelled");
    setMessage("Appointment cancelled. The applicant has been notified to reschedule the appointment.");
    setBtnDisplay("none");
    setCloseDisplay("block");
    // onClose();
    // navigate(`/qr`);
  }

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value: string) => {
    onClose();
  };

  const [ btnDisplay, setBtnDisplay ] = React.useState('block');
  const [ closeDisplay, setCloseDisplay ] = React.useState('none');
  // const [ closeDisplay, setCloseDisplay ] = React.useState(false);

  const close = () => {
    setBtnDisplay("block");
    setCloseDisplay("none");
    onClose();
    navigate(`/qr`);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="alert-dialog-title">
          {header}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{display: btnDisplay}} onClick={cancelAppointment}>Yes</Button>
        <Button sx={{display: btnDisplay}} onClick={handleClose}>No</Button>
        <Button sx={{display: closeDisplay}} onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

const SendDetailsDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const { open, onClose } = props;
  let navigate = useNavigate();

  const [header, setHeader] = React.useState("Send appointment details?");
  const [message, setMessage] = React.useState("An appointment confirmation email will be sent to the applicant.");

  
  const sendDetails = () => {
    setMessage("A confirmation email and text was sent to the information on file.");
    setHeader("Applicant Notified");
    setBtnDisplay("none");
    setCloseDisplay("block");
    // onClose();
  };
  
  const [ btnDisplay, setBtnDisplay ] = React.useState('block');
  const [ closeDisplay, setCloseDisplay ] = React.useState('none');
  // const [ closeDisplay, setCloseDisplay ] = React.useState(false);

  const close = () => {
    setBtnDisplay("block");
    setCloseDisplay("none");
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title">
        {header}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{display: btnDisplay}} onClick={sendDetails}>Yes</Button>
        <Button sx={{display: btnDisplay}} onClick={onClose}>No</Button>
        <Button sx={{display: closeDisplay}} onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}


export interface Props { 

}

interface BioProps {
  client: string
}

export const PassportComponent: React.FC<BioProps> = (props) => {
  const classes = useStyles();

  const location = useLocation();
  const { t, i18n } = useTranslation();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  // Find Id image based on client Id
  const clientImageSearch = () => {
    let client_id = props.client;
    if (client_id == undefined) client_id = "1";
    const logo = require(`../../static/images/DIRS/${client_id}/portrait.jpg`);

    return <img src={String(logo)} width="80%" style={{ borderRadius: '50%' }} />


  }

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent='center'
      height='100%'
      width='100%'
    >
      <Stack spacing={1} sx={{ margin: 'auto' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
          }}
        >
          {clientImageSearch()}
        </Box>
      </Stack>
    </Grid>
  );
};

const AdminScheduleDetails: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const [sendOpen, setSendOpen] = React.useState(false);
  // const selectedId = useAppSelector((state: any) => state.kioskSettings.selectedId);
  const dispatch = useAppDispatch();
  // const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  
  const [selectedIndex, setSelectedIndex] = React.useState<number>(useAppSelector((state: any) => state.kioskSettings.selectedId));
  const appointment = JSON.parse(localStorage.getItem('scheduleChanges') || String([]))[selectedIndex];

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const alert = () => {
    window.alert('Email is not configured yet.')
  }

  const goAdminScreen = () => {
    navigate(`/admin`);
  }

  const handleClose = () => {
    setCancelOpen(false);
  };
  
  const handleSendOpen = () => {
    setSendOpen(true);
  };

  const handleSendClose = () => {
    setSendOpen(false);
  };

  // const selectedIndex = useAppSelector((state: any) => state.kioskSettings.selectedId);

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    // console.log(`id: ${selectedId}`);
    // setSelectedIndex(useAppSelector((state: any) => state.kioskSettings.selectedId));
  }, []);

  const handleReschedule = (event: React.MouseEvent<HTMLButtonElement>) => {
    // console.log(`index2: ${selectedId}`);
    // localStorage.setItem('selectedIndex', selectedId);
    navigate("/reschedule");
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCancelOpen(true);
  };

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const state: any = location.state;
  // const appointment = scheduled[selectedId];
 
  const breadcrumbs = [{label: "Schedule Main", link: "/adminSchedule", color: 'white'},
      {label: `${appointment.firstName} ${appointment.lastName}`, link: "/adminScheduleDetails", color: 'white'},
    ];

  return (
    <Header> 
      <ApplicantBreadcrumb items={breadcrumbs}/>
      <Grid container style={{marginTop: 60}}>
        <Grid item xs={2}></Grid>
        <Grid container item xs={8} justifyContent='center'>
        <Stack>
          <Typography
                variant='h3'
                align='center' 
                sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
              >
                APPOINTMENT DETAILS
              </Typography>
              <Stack direction="row">
                <CheckCircleIcon sx={{color: 'green', fontSize: '50px', alignItems: 'center', marginRight: '5px'}}/> 
                <Typography
                      align='center'
                      component='div'
                      variant='h4'
                      sx={{ fontWeight: 'bold', color: '#BCD63E', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
                    >
                      Appointment Confirmed
                    </Typography>
                  </Stack>
              </Stack>
            <Stack spacing={4} style={{marginTop: 60, width: '100%'}} justifyContent='center' alignItems='center'>
            <SplitPane2 sx={{width: '850px' }}
                  pane1={
                    <PassportComponent client={appointment.clientId} />
                  }
                  pane2={

                    <Stack spacing={4} justifyContent='center'>
                  <List sx={{width: '450px'}}>
                    <ListItem>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('name')}:</Typography>
                        }/>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
                            {appointment.firstName} {appointment.lastName}
                          </Typography>
                        } />
                    </ListItem>

                    <ListItem>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>Appointment:</Typography>
                        }/>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
                            {appointment.date} {appointment.time}
                          </Typography>
                        } />
                    </ListItem>

                    <ListItem>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>Type:</Typography>
                        }/>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
                            Biometrics
                          </Typography>
                        } />
                    </ListItem>

                    <ListItem>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('gender')}:</Typography>
                        }/>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
                            {appointment.sex}
                          </Typography>
                        } />
                    </ListItem>

                    <ListItem>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('height')}:</Typography>
                        }/>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
                            {appointment.height} 
                          </Typography>
                        } />
                    </ListItem>

                    <ListItem>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('dob')}:</Typography>
                        }/>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
                            {appointment.dob}
                          </Typography>
                        } />
                    </ListItem>

                    <ListItem>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('country')}:</Typography>
                        }/>
                      <ListItemText sx={{width: '200px', paddingLeft: '10px', color: '#000000', fontWeight: '600', fontSize: '1.5em !important'}} primary=
                        {
                          <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
                            {appointment.country}
                          </Typography>
                        } />
                    </ListItem>
                  </List>            
                </Stack>
                  }
                ></SplitPane2>
                <Stack direction="row" spacing={3}>
                  <Button
                    variant='contained'
                    component={Link}
                    to='/adminSchedule'
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '300px', fontFamily: 'PoppinsNormal'}}
                  >
                    Back
                  </Button>
                  <Button
                    variant='contained'
                    // component={Link}
                    // to='/reschedule'
                    onClick={handleReschedule}
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '300px', fontFamily: 'PoppinsNormal'}}
                  >
                    Reschedule
                  </Button>
                  <Button
                    variant='contained'
                    // component={Link}
                    // to='/reschedule'
                    onClick={handleSendOpen}
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '300px', fontFamily: 'PoppinsNormal'}}
                  >
                    Resend Details to Applicant
                  </Button>
                  <Button
                    variant='contained'
                    // component={Link}
                    // to='/reschedule'
                    onClick={handleCancel}
                    style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '300px', fontFamily: 'PoppinsNormal'}}
                  >
                    Cancel
                  </Button>
                  <CancelDialog
                    open={cancelOpen}
                    onClose={handleClose}
                  />
                  <SendDetailsDialog
                    open={sendOpen}
                    onClose={handleSendClose}
                  />
                </Stack>
          </Stack>
          <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleAlertClose} severity="success" sx={{ width: '350px' }}>
              {message}
            </Alert>
          </Snackbar>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Header>
  );
};

export default AdminScheduleDetails;
