import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
import LargeCard from '../../components/LargeCard/LargeCard';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useStyles } from './styled';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Header from '../Header/';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import ApplicantBreadcrumb from '../../components/ApplicantBreadcrumb/ApplicantBreadcrumb';

export interface Props {}

const PaymentPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  const breadcrumbs = [{label: "Kiosk Authentication", link: "/kioskAuth", color: 'white'},
    {label: "Passport OCR Scan", link: "/passportOcrScan", color: 'white'},
    {label: "Scan", link: "/scanProgress", color: 'white'},
    {label: "Passport ID Verified", link: "/passportIdCompare", color: 'white'},
    {label: "ID Confirmed", link: "/passportOcr", color: 'white'},
    {label: "Timeline", link: "/timeline", color: 'white'},
    {label: "Payment Required", link: "/paynow", color: 'white'},
    {label: "Payment", link: "/payment", color: 'white'}
  ];

  return (
    <Header>
      <ApplicantBreadcrumb items={breadcrumbs}/>
      <Grid container style={{marginTop: 100}}>
      <Grid item xs={3}></Grid>
      <Grid container item xs={6} justifyContent='center'>
        <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              {t('pay1')}
            </Typography>
            <Typography
                  variant='h4'
                  align='center'
                  sx={{ fontWeight: 'bold', color: '#BCD63E', fontFamily:'Poppins', letterSpacing: '3px', marginBottom: '30px'}}
                >
                  {t('pay2')}
                </Typography>
              <Stack spacing={4} style={{marginTop: 100}} justifyContent='center'>
                <Button
                  variant='contained'
                  component={Link}
                  to='/paymentInsert'
                  style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                >
                  {t('pay3')}
                </Button>
                <Button
                  variant='contained'
                  component={Link}
                  to='/paymentInsert'
                  style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                >
                  {t('pay4')}
                </Button>
                <Button
                  variant='contained'
                  component={Link}
                  to='/paymentInsert'
                  style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                >
                  {t('pay5')}
                </Button>
                <Button
                  variant='contained'
                  component={Link}
                  to='/checkInsert'
                  style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                >
                  {t('pay6')}
                </Button>
          </Stack>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  </Header>
  );
};

export default PaymentPage;
