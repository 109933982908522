import { makeStyles } from '@mui/styles';



export const useStyles = makeStyles({
  emailSubmitButton: {
    backgroundColor: '#31CBEF',
    color: 'white',
    fontWeight: 'bold ',
    width: 200,
    alignItems: 'center',
    margin: 15,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
  },
});
