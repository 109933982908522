import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  passportSubmitButton: {
    backgroundColor: '#006BB5',
    color: 'white',
    fontWeight: 'bold ',
    width: 200,
    alignItems: 'center',
    margin: 15,
  },
  labels: {
    fontSize: '25px',
    fontWeight: 700,
  },
  languageBox: {
    backgroundColor: 'white',
    padding: '50px',
    borderRadius: 10,
    width: '40%',
    height: '550px',
  },
});
