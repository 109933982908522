import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Box,
  AppBar,
  Typography,
  Button,
  IconButton,
  Toolbar,
  Grid,
  Link

} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useStyles } from './styled';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import { useContext, useEffect } from 'react';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
// import V1COM from '../../static/images/vicomlogo.png';
import V1COM from '../../static/images/vicomgraysolution.png';
// import V1COM from '../../static/images/V1COM.png';
import ChatBotComponent from '../../pages/ChatBot';
import AlertBox from '../../components/Dialog/Dialog'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


export interface Props { }

const Header: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
  }, []);

  let [showChat, setShowChat] = useState(false);

  const startChat = () => {
    if (showChat) setShowChat(false);
    else setShowChat(true);
  }

  const goHome = () => {
    navigate(`/`);
  }

  console.log(context.first_name);
  return (
    <>
      <Grid container xs={12}  >
        <Grid item xs={12} >
          <AppBar style={{ background: '#ffffff', color: '#3c6cb2', paddingBottom: '5px', paddingTop: '5px' }}>

            <Toolbar>
              <Grid item xs={2} >
              
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ ml: 10 }}
                >
                  <img src={V1COM} width={150} onClick={() => goHome()}/>
                </IconButton>
              </Grid>
              <Grid xs={4} />
              <Grid item xs={4} >

                <AlertBox />

              </Grid>
              <Grid item xs={2} style={{ float: 'right' }} >

                <Button style={{ background: '#ffffff' }} onClick={() => startChat()}>
                  <Typography >
                    <b>{t('help')}</b>
                    &nbsp;
                  </Typography>

                  <ChatBubbleIcon style={{ color: '#3c6cb2', fontSize: '30px' }} />
                </Button>
              </Grid>
            </Toolbar>
          </AppBar>

        </Grid>
        <Grid item xs={12} style={{ marginTop: '75px' }} >
          {children}
        </Grid>

      </Grid>
      <div style={{ display: showChat ? "" : "none", position: 'absolute', right: 70, bottom: '0' }}>
        <ChatBotComponent />
      </div>
    </>
  )
}
export default Header;
