import * as React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import EmailLoginPage from './pages/EmailLoginPage';
import KioskAuthenticate from './pages/KioskAuthenticate';
import PassportOCRPage from './pages/PassportOCRPage';
import PassportOCRScanPage from './pages/PassportOCRScanPage';
// import PassportScanDialogPage from './pages/PassportScanDialogPage';
import PersonaPage from './pages/PersonaPage';
import SelectLanguagePage from './pages/SelectLanguagePage';
import ThumbprintSuccessPage from './pages/ThumbprintSuccessPage';
import BiometricsCollectionPage from './pages/BiometricsCollectionPage';
import VisitorRegistrationPage from './pages/VisitorRegistrationPage';
import PassportIDComparePage from './pages/PassportIDComparePage';
import WelcomePage from './pages/WelcomePage/';
import ThankYouPage from './pages/ThankYouPage';
import QRPage from './pages/QRPage';
import TimelinePage from './pages/TimelinePage';
import PaymentPage from './pages/PaymentPage';
import SignaturePage from './pages/SignaturePage';
import ReturningVisitorPage from './pages/ReturningVisitorPage';
import PhoneLoginPage from './pages/PhoneLogin';
import PassportOCRScanProgressPage from './pages/PassportOCRScanProgressPage';
import PayNowPage from './pages/PayNowPage';
import CreditCardScanPage from './pages/CreditCardScanPage';
import CreditCardProcessingPage from './pages/CreditCardProcessingPage';
import PaymentProcessed from './pages/PaymentProcessed';
import BiometricsNowPage from './pages/BiometricsNowPage';
import BiometricsProcessedPage from './pages/BiometricsProcessedPage';
import RejectedStatusPage from './pages/RejectedStatusPage';
import FormEmailedPage from './pages/FormEmailedPage';
import AdminPage from './pages/AdminPage';
import AdminSchedulePage from './pages/AdminSchedulePage';
import AdminScheduleDetails from './pages/AdminScheduleDetails';
import AdminReschedulePage from './pages/AdminReschedulePage';
import SchedulePage from './pages/SchedulePage';
import AnalyticsPage from './pages/AnalyticsPage';
import CameraPage from './pages/CameraPage';
import CheckScanPage from './pages/CheckScanPage';
import CheckProcessingPage from './pages/CheckProcessingPage';


const Router: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<WelcomePage />} />
      <Route path='/selectLang' element={<SelectLanguagePage />} />
      <Route path='/visitorReg' element={<VisitorRegistrationPage />} />
      <Route path='/persona' element={<PersonaPage />} />
      <Route
        path='/kioskAuth'
        element={
          <div>

            <KioskAuthenticate />
          </div>
        }
      />
      <Route
        path='/thumbprintSuccess'
        element={
          <div>

            <ThumbprintSuccessPage />
          </div>
        }
      />
      <Route
        path='/emailLogin'
        element={
          <div>

            <EmailLoginPage />
          </div>
        }
      />
      <Route
        path='/returnVisitor'
        element={
          <div>

            <ReturningVisitorPage />
          </div>
        }
      />
      {/* <Route path='/passportScanDialog' element={<PassportScanDialogPage />} /> */}
      <Route
        path='/passportOcrScan'
        element={
          <div>

            <PassportOCRScanPage />
          </div>
        }
      />
      <Route
        path='/passportOcr'
        element={
          <div>

            <PassportOCRPage />
          </div>
        }
      />
      <Route
        path='/passportIdCompare'
        element={
          <div>

            <PassportIDComparePage />
          </div>
        }
      />
      <Route
        path='/timeline'
        element={
          <div>

            <TimelinePage />
          </div>
        }
      />
        <Route
        path='/biometrics'
        element={
          <div>

            <BiometricsCollectionPage />
          </div>
        }
      />
      <Route
        path='/payment'
        element={
          <div>

            <PaymentPage />
          </div>
        }
      />
      <Route
        path='/thankYou'
        element={
          <div>

            <ThankYouPage />
          </div>
        }
      />
      <Route
        path='/qr'
        element={
          <div>

            <QRPage />
          </div>
        }
      />
      <Route
        path='/signature'
        element={
          <div>

            <SignaturePage />
          </div>
        }
      />
      <Route
        path='/phone'
        element={
          <div>

            <PhoneLoginPage />
          </div>
        }
      />
      <Route
        path='/scanProgress'
        element={
          <div>

            <PassportOCRScanProgressPage />
          </div>
        }
      />
      <Route
        path='/paynow'
        element={
          <div>

            <PayNowPage />
          </div>
        }
      />
      <Route
        path='/paymentProcessing'
        element={
          <div>

            <CreditCardProcessingPage />
          </div>
        }
      />
      <Route
        path='/paymentInsert'
        element={
          <div>

            <CreditCardScanPage />
          </div>
        }
      />
      <Route
        path='/paymentProcessed'
        element={
          <div>

            <PaymentProcessed />
          </div>
        }
      />
      <Route
        path='/checkInsert'
        element={
          <div><CheckScanPage /></div>
        }
      />
      <Route
        path='/checkProcessing'
        element={
          <div><CheckProcessingPage /></div>
        }
      />
      <Route
        path='/biometricsNow'
        element={
          <div>

            <BiometricsNowPage />
          </div>
        }
      />
      <Route
        path='/biometricsSuccess'
        element={
          <div>

            <BiometricsProcessedPage />
          </div>
        }
      />
      <Route
        path='/rejectedStatus'
        element={
          <div>

            <RejectedStatusPage />
          </div>
        }
      />
      <Route
        path='/formEmailed'
        element={
          <div>

            <FormEmailedPage />
          </div>
        }
      />
      <Route
        path='/admin'
        element={
          <div>

            <AdminPage />
          </div>
        }
      />
      <Route
        path='/adminSchedule'
        element={
          <div>

            <AdminSchedulePage/>
          </div>
        }
      />
      <Route
        path='/adminScheduleDetails'
        element={
          <div>

            <AdminScheduleDetails/>
          </div>
        }
      />
      <Route
        path='/reschedule'
        element={
          <div>

            <AdminReschedulePage/>
          </div>
        }
      />
      <Route
        path='/schedule'
        element={
          <div>

            <SchedulePage/>
          </div>
        }
      />
      <Route
        path='/analytics'
        element={
          <div>

            <AnalyticsPage/>
          </div>
        }
      />
      <Route
        path='/camera'
        element={
          <div>

            <CameraPage/>
          </div>
        }
      />
    </Routes>
  );
};

export default Router;
// <Route exact path='/login' element={<Login />} />
// <Route
//   exact
//   path='/recovery-password'
//   element={<RecoveryPassword />}
// />
// <Route path='*' element={<NotFound />} />
