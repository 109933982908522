import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import saiclogo from '../../static/images/SAIC_Logo_Package/Digital/4_White/SAIC-Logo-RGB-White-sm.png';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import saicTri from '../../static/images/saicTri.png';
// import logo from '../../static/images/V1COM-white.png';
import logo from '../../static/images/vicomwhitesolution.png';
import Stack from '@mui/material/Stack';
import { useStyles } from './styled';
import styled from 'styled-components';
import DateTime from "../../components/DateTime";

interface Props { };

export const ReviewDate = 
styled(DateTime)(() => ({
  fontSize: '100px',
  fontWeight: '600',
  color: 'red !important'
}));

const WelcomePage: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      minHeight='100vh'
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant='h1'
            component='h2'
            align='center'
            sx={{color: '#ffffff', fontFamily: 'PoppinsNormal', fontSize: '4rem' }}
          >
            <img src={logo} style={{width: '800px'}}/>
            <br/>
           Welcome to the<br/> Demo Experience<br/>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
            style={{marginTop: '40px'}}
            // style={{ minHeight: '50vh' }}
          >
            <Grid item xs={12} columnSpacing={1}>
          
              <Button
              component={Link}
                to='/selectLang'
                variant='outlined'
                style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', fontFamily: 'PoppinsNormal'}}
              >
                Touch to Initialize &nbsp;<ArrowForwardIosIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{bottom: '30px', position: 'absolute', right: 0}}>
          <Stack>
            <Typography
              component='div'
              align='center'
              // marginLeft={25}
              sx={{color: '#ffffff', fontFamily: 'PoppinsNormal', fontSize: '24px',}}
            >
              Powered By
              </Typography>
              <img src={saiclogo} height="100px"/>
          </Stack>
          
        </Grid>
      </Grid>
    </Box>
  );
};

export default WelcomePage;