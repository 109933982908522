import { makeStyles } from '@mui/styles';
import triangleBg from '../../static/images/borderchallengebk.png';

export const useStyles = makeStyles({
  box: {
    backgroundColor: 'white',
    border: 0,
    borderRadius: 15,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'black',
    padding: '20px',
  },
  background: {
    backgroundImage: 'url(' + triangleBg + ')',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'left bottom'
  },
  languageButton: {
    backgroundColor: '#E1E1E1',
    color: 'black',
    fontWeight: 'bold ',
    padding: 30,
  },
  languageBox: {
    backgroundColor: 'white',
    padding: '50px',
    borderRadius: 10,
    width: '40%',
    height: 'auto',
  },
});
