import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Box,
       Grid,
       Typography,
       Card,
       Paper,
       Avatar,
       Skeleton
} from '@mui/material/';

import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
// const matches = useMediaQuery('(min-width:600px)');

// return <span>{`(min-width:600px) matches: ${matches}`}</span>;
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useStyles } from './styled';
import LargeCard from '../../components/LargeCard/LargeCard';
import Header from '../Header'
import CropFreeIcon from '@mui/icons-material/CropFree';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';


export interface Props {}

const CreditCardProcessingPage: React.FC<Props> = (props) => {
  const classes = useStyles();

  let navigate = useNavigate();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [state, setState] = React.useState({
    isLoading: false
  })

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    setTimeout(() => {
      navigate(`/paymentProcessed`);
      }, 3000) 
  },[]);

  return (
    <Header>
      <Grid container style={{marginTop: 100}}>
         <Grid item xs={3}></Grid>
         <Grid container item xs={6} justifyContent='center'>
            <Typography
              variant='h3'
              align='center'
              sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
            >
              {t('payProcess')}
            </Typography>
             <Stack spacing={6} style={{marginTop: 100, width:'100%', textAlign: 'center'}} justifyContent='center' justifyItems='center' >
                <LinearProgress />
              </Stack>
         </Grid>
         <Grid item xs={3}></Grid>
      </Grid>
    </Header>
  );
};

export default CreditCardProcessingPage;
