import React, { useState } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  Box,
  Grid,
  Stack,
  Avatar
} from '@mui/material';
import LargeCard from '../../components/LargeCard/LargeCard';
import { Link, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useStyles } from './styled';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SplitPane2 from '../../components/SplitPane2/SplitPane2';
import { PersonaContext } from '../../context/PersonaContext';
import { LanguageContext } from '../../context/LanguageContext';
import AvatarComponent from '../../components/AvatarComponent/AvatarComponent';
import Header from '../Header/'
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ApplicantBreadcrumb from '../../components/ApplicantBreadcrumb/ApplicantBreadcrumb';

export interface Props { }

interface BioProps {
  client: string
}

export const PassportComponent: React.FC<BioProps> = (props) => {
  const classes = useStyles();

  console.log(props.client)

  const location = useLocation();
  const { t, i18n } = useTranslation();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    console.log(`language ${langContext.language}`);
  }, []);

  // Find Id image based on client Id
  const clientImageSearch = () => {
    let client_id = props.client;
    if (client_id == undefined) client_id = "1";
    const logo = require(`../../static/images/DIRS/${client_id}/portrait.jpg`);

    return <img src={String(logo)} width="80%" style={{ borderRadius: '50%' }} />


  }


  return (
    <Grid
      container
      item
      xs={12}
      justifyContent='center'
      height='100%'
      width='100%'
    >
      <Stack spacing={1} sx={{ margin: 'auto' }}>
        <Typography style={{ color: '#3c6cb2' }}>{t('passportScan')}</Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%',
          }}
        >
          {clientImageSearch()}
        </Box>
      </Stack>
    </Grid>
  );
};

type OCRProps = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: string;
  issueDate?: string;
  expirationDate?: string;
  passportNumber?: string;
  country?: string;
};

export const PassportOcrComponent = (props: OCRProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    console.log(`language ${langContext.language}`);
  }, []);

  // Make a Post Request
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // console.log(formValues);
    // createVisitorRecord(formValues)
    // .then((response: any) => {
    //   // setState(response.data);
    //   // console.log(response.data);
    //   let personaData: string = response.data;
    //   navigate(`/kioskAuth`, {
    //     state: { persona: personaData, language: language },
    //   });
    // })
    // .catch((e: Error) => {
    //   console.log(e);
    // });
    navigate(`/kioskAuth`);
  };

  return (
    <Stack spacing={1}>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('name')}:</Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>
        {props.firstName} {props.middleName} {props.lastName}
      </Typography>
      <div className="dividerShape" />
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('dob')}:</Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>{props.dob}</Typography>
      <div className="dividerShape" />
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('issueDate')}:</Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>{props.issueDate}</Typography>
      <div className="dividerShape" />
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>
        {t('expDate')}:
      </Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>{props.expirationDate}</Typography>
      <div className="dividerShape" />
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#3563a7'}}>{t('country')}:</Typography>
      <Typography sx={{fontFamily: 'PoppinsNormal',padding: '10px', fontSize: '16px',color: '#000'}}>{props.country}</Typography>
      <div />
    </Stack>
  );
};

const PassportOCRPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const context = useContext(PersonaContext);
  const langContext = useContext(LanguageContext);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(langContext.language);
    console.log(`language ${langContext.language}`);
  }, []);

  let language = langContext.language;
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // console.log(formValues);
    // alert('hello');
    // console.log('Creating visitor');
    // context.createVisitor();
    navigate(`/timeline`);
    // createVisitorRecord(formValues)
    //   .then((response: any) => {
    //     // setState(response.data);
    //     // console.log(response.data);
    //     let personaData: string = response.data;
    //     navigate(`/persona`, {
    //       state: { persona: personaData, language: language },
    //     });
    //   })
    //   .catch((e: Error) => {
    //     console.log(e);
    //   });
  };

  const currentClient: any = JSON.parse(localStorage.getItem("currentClient") || '{}');

  const breadcrumbs = [{label: "Kiosk Authentication", link: "/kioskAuth", color: 'white'},
    {label: "Passport OCR Scan", link: "/passportOcrScan", color: 'white'},
    {label: "Scan", link: "/scanProgress", color: 'white'},
    {label: "Passport ID Verified", link: "/passportIdCompare", color: 'white'},
    {label: "ID Confirmed", link: "/passportOcr", color: 'white'}
  ];

  return (
    <PersonaContext.Consumer>
      {(client) => (
         <Header>
            <ApplicantBreadcrumb items={breadcrumbs}/>
         <Grid container style={{marginTop: 60}}>
            <Grid item xs={3}></Grid>
            <Grid container item xs={6} justifyContent='center'>
               <Typography
                 variant='h3'
                 align='center'
                 sx={{ fontWeight: 'bold', color: '#fff', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
               >
                 {t('ocr1')}
               </Typography>
               <Typography
                 variant='h4'
                 align='center'
                 sx={{ fontWeight: 'bold', color: '#BCD63E', marginBottom: 0, fontFamily:'Poppins', letterSpacing: '3px'}}
               >
                 {t('hello')} {currentClient.first_name.toUpperCase()} {currentClient.last_name.toUpperCase()}
               </Typography>
                <Stack spacing={4} style={{marginTop: 60, width: '100%'}} justifyContent='center' alignItems='center'>
                <SplitPane2
                  pane1={
                    <PassportComponent client={currentClient.client_id} />
                  }
                  pane2={

                    <PassportOcrComponent
                      firstName={currentClient.first_name}
                      middleName={currentClient.middle_name}
                      lastName={currentClient.last_name}
                      dob={currentClient.birth_date}
                      issueDate={'2022-03-23'}
                      expirationDate={'2042-03-22'}
                      passportNumber={currentClient.passport_number}
                      country={currentClient.country}
                    />
                  }
                ></SplitPane2>
                   <Button
                     variant='contained'
                     onClick={(e) => handleSubmit(e)}
                     style={{borderRadius:'50px',border: '3px solid rgb(1, 76, 128)', color: 'rgb(1, 76, 128)', backgroundColor: '#fff', height: '70px', padding: '20px', width: '450px', fontFamily: 'PoppinsNormal'}}
                   >
                     {t('continue')}
                   </Button>
                 </Stack>
            </Grid>
            <Grid item xs={3}></Grid>
         </Grid>
       </Header>
      )}
    </PersonaContext.Consumer>
  );
};

export default PassportOCRPage;
